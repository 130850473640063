import {useEffect, useState} from "react";
import {deleteFarm, getFarmList} from "../../api/FarmsApi";

import {store} from "../../redux/store";
import '../../assets/css/home.css'
import Layout from "../layouts/Layout";
import HeaderFarmer from "../layouts/HeaderFarmer";
import Footer from "../layouts/Footer";
import {alertError, alertSuccess, confirmPopupText, dismissPopup, showProgress} from "../../components/AlertDialogs";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMagnifyingGlass, faTrash} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import {debounce} from "lodash";
import Longan_tree from "../../assets/images/Longan_tree";
import FarmMap from "../../components/FarmMap";
function Home() {
    // eslint-disable-next-line no-unused-vars
    const [farm_id, setFarmID] = useState(null);
    const [search, setSearch] = useState('');
    const [farms, setFarms] = useState([]);
    const navigate = useNavigate();
    const [showMap,setShowMap] = useState(false);
    const [polygon, setPolygon] = useState({});
    const [location, setLocation] = useState();
    const [name, setName] = useState();
    let user = store.getState() && store.getState().app && store.getState().app.user;
    useEffect(() => {
        getData(search)
    }, []);

    function getData(search){
        showProgress('')
        getFarmList(user.id,search)
            .then(res => {
                if (res.data && res.data.success) {
                    setFarms(res.data.data);
                    if (setFarmID && res.data.data.length > 0) {
                        setFarmID(res.data.data[0].id)
                    }
                }
            }).finally(() => {
            dismissPopup()
        })
    }

    const debounceSearch = debounce((v) => {
        setSearch(v)
        getData(v)
    }, 800)

    const getPolygonFarm =(poly,loca,na) => {
        setShowMap(true)
        setPolygon(poly)
        setLocation(loca)
        setName(na)
    }


    function Delete(id){
        confirmPopupText('ลบสวน', 'คุณต้องการสวนนี้หรือไม่',()=>{
            showProgress('')
            deleteFarm(id)
                .then(res=>{
                    if (res.data && res.data.success){
                        alertSuccess('สำเร็จ')
                        setTimeout(() => {
                            dismissPopup()
                            getData('')
                            navigate('/home')
                        }, 1000)
                    }else{
                        alertError('ไม่สำเร็จ', (res.data && res.data.message) || '')
                    }
                })
        })
    }


    return (
        <div className={'Container'}>
            <HeaderFarmer HeadTitle={'รายการสวน'} showBack={[true, true]} showRight={true} showAddFarm={true}/>
            <Layout>
                <div className={'d-flex'}>
                    <div className={'input-group mb-3'} style={{width: '100%'}}>
                        <div className={'input-group-text'}>
                            <FontAwesomeIcon icon={faMagnifyingGlass}
                                             className={'text-color-primary icon bg-second'}/>
                        </div>
                        <input type="text" className={'form-control'} placeholder={'ค้นหา'} onChange={(e) => debounceSearch(e.target.value)}/>
                    </div>
                </div>
                {
                    farms.length > 0 ? (
                            farms.sort((a, b) =>b.id - a.id ).map((farm,index)=>(
                                <div className={'card bg-second mb-3 p-3'} key={index} >
                                    <div className={'w-100 mb-3'}>
                                        {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                                        <img src={farm.full_picture_path} alt="no image" width={'100%'} height={200} style={{borderRadius:14,objectFit:"cover",position:"relative"}}/>
                                    </div>
                                    <div onClick={()=>getPolygonFarm(farm.polygons,farm.location,farm.name)}>
                                        <h1 className={'fw-bold text-dark'} style={{overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap",fontSize:16}}>
                                            ชื่อสวน : <span className={'text-muted fw-normal'} >{farm.name}</span>
                                        </h1>
                                        <h1 style={{fontSize:16}} className={'fw-bold text-dark'}>
                                            พิกัดสวน : <span className={'text-muted fw-normal'}>{farm.location}</span>
                                        </h1>
                                        <h1 style={{fontSize:16}} className={'fw-bold text-dark m-0'}>
                                            ขนาดพื้นที่ : <span className={'text-muted fw-normal'}>{farm.amount_of_rai} ไร่ {farm.amount_of_square_wa} ตร.ว.</span>
                                        </h1>
                                    </div>


                                    <div className={'position-absolute'} style={{top:10,right:10}}>
                                        <button className={'btn btn-primary'} style={{height:40,width:40,background:'rgb(255, 242, 242)',borderRadius:12}}
                                                onClick={()=>Delete(farm.id)}>
                                            <FontAwesomeIcon icon={faTrash} fontSize={14} color={'rgb(215, 61, 71)'}/>
                                        </button>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className='d-flex justify-content-center align-items-center text-center' style={{height:'50vh'}}>
                                <div>
                                    {/* eslint-disable-next-line react/jsx-pascal-case */}
                                    <Longan_tree/>
                                    <h2 style={{fontSize:18}}>ไม่พบข้อมูลสวน</h2>
                                </div>
                            </div>
                        )
                }

            </Layout>
            <Footer/>

            {showMap && <FarmMap modalIsOpen={showMap}
                                 closeModal={()=>setShowMap(false)}
                                 polygon={polygon}
                                 location={location}
                                 name={name}
            />
            }
        </div>

    )
}

export default Home;
