import Api from "./Api";
import ApiImage from "./ApiImage";

export const get = (path,param = {}) => {
  return Api.get(path,param).then(res => {
    return res;
  })
}

export const getBlob = (path,param = {}) => {
  return ApiImage.get(path,param).then(res => {
    return res;
  })
}
export const post = (path, data) => {
  return Api.post(path, data).then(res => {
    return res;
  })
}

export const put = (path, data) => {
  return Api.put(path, data).then(res => {
    return res;
  })
}

export const remove = (path) => {
  return Api.delete(path).then(res => {
    return res;
  })
}
