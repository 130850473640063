import HeaderFarmer from "./layouts/HeaderFarmer";
import Layout from "./layouts/Layout";
import Footer from "./layouts/Footer";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {store} from "../redux/store";
import {useEffect, useState} from "react";
import {generateOnetimeToken, getAppLists} from "../api/ApplicationAPI";
import {alertError, dismissPopup, showProgress} from "../components/AlertDialogs";
import FooterFarmer from "./layouts/FooterFarmer";
import {getUserById} from "../api/AuthApi";
import ModalCheckFarm from "./farm/ModalCheckFarm";
import {getFarmList} from "../api/FarmsApi";
import "../assets/css/home.css";
import Longan_tree from "../assets/images/Longan_tree";
import Pageline from "../assets/icons/Pageline";
import Seedling from "../assets/icons/Seedling";
import Trillium from "../assets/icons/Trillium";
import Flower from "../assets/icons/Flower";
import Olive from "../assets/icons/Olive";
import Basket from "../assets/icons/Basket";


function Application() {
    /* eslint-disable no-unused-vars */
    const [applications, setApplication] = useState([])
    const [userId, setUserId] = useState();
    const [userType, setUserType] = useState();
    const [user, setUser] = useState({});
    const [showModal, setShowModal] = useState(false);
    const location = useLocation();
    const params = useParams();
    const [typeId, setTypeId] = useState(null);
    const [dataId, setDataId] = useState(null);

    useEffect(() => {
        const pathSegments = location.pathname.split('/');
        const extractedTypeId = pathSegments[2];
        setTypeId(Number(extractedTypeId));
        const typeToData = {
            1: ['ตัดแต่งกิ่ง','#FFDFC3','#EB7100',<Pageline color='#EB7100' width='22' height='22'/>,'#FFEEDF'],
            2: ['ใบแก่','#CCF0BD','#53853D',<Seedling color='#53853D' width='22' height='22'/>,'#EEFCE8'],
            3: ['ชักนำช่อดอก','#FFF09A','#C19700',<Trillium color='#C19700' width='22' height='22'/>,'#FFF8D1'],
            4: ['แทงช่อดอก','#FDD0D0','#CB262D',<Flower color='#CB262D' width='22' height='22'/>,'#FFE7E7'],
            5: ['ติดผล','#E3E79B','#82881D',<Olive color='#82881D' width='22' height='22'/>,'#F1F3C8'],
            6: ['เก็บเกี่ยวผลผลิต','#A8F0F5','#0DA1AB',<Basket color='#0DA1AB' width='22' height='22'/>,'#D5FAFD'],
            //ชื่อ background colorFont&colorIcon Icon backgroundIcon
        };
        setDataId(typeToData[extractedTypeId] || []);

        getUserAndApp();
    }, [typeId]);
    function getUserAndApp() {
        setUserType(store.getState().app.user.type)
        const user_id = store.getState().app.user.id
        const id = params.id;
        setUserId(user_id)
        showProgress('')
        getAppLists('')
            .then(res => {
                const data = res.data;
                let appData = [];
                data.map(d => {
                    if(d.show_display) {
                        Object.keys(d.show_display).map((key) => {
                            if(key == id && d.show_display[key] == true) {
                                appData.push(d);
                            }
                        })
                    }
                })
                setApplication(appData)
            }).finally(()=>{
            getUserById(user_id).then(res => {
                const datauser = res.data.data
                setUser(datauser)
            }).finally(()=>{
                dismissPopup()
            })
        })
        if (store.getState().app.user.type === 'FARMER'){
            getFarmList(user_id,'').then(res=>{
                if (res.data && res.data.success){
                    if(res.data.data.length <= 0){
                        setShowModal(true)
                    }
                }
            })
        }
    }

    const openApp = (app_data) => {
        showProgress('')
        generateOnetimeToken(app_data.id)
          .then(res => {
              if(res.data && res.data.success){
                  dismissPopup()
                  window.location.href = `${app_data.url}?token=${res.data.data}`
              }else {
                  alertError('', 'เกิดข้อผิดพลาดกรุณาติดต่อผู้ดูแลระบบ')
              }
          })
    }

    return (
        <>
            <div className={'Container'}>
                <HeaderFarmer showBack={true} showSetting={true} HeadImage={true} showRight={true} />
                <Layout>
                    <div className={'d-flex w-100 align-items-center'} style={{borderRadius: 14, background: dataId && dataId[4]}}>
                        <div className={'me-2'} style={{height: '100%', borderRadius: 14, padding: 10, background: dataId && dataId[1]}}>
                            {dataId && dataId[3]}
                        </div>
                        <div style={{fontSize: 20, color: dataId && dataId[2]}}>
                            {dataId && dataId[0]}
                        </div>
                    </div>
                    <div className={'CARD mt-3'}>
                        <div className={'row g-3'}>
                            {applications.length > 0 && applications.filter(data=> data.show_status).length > 0 ? (
                                applications.filter(data=> data.show_status).sort((a, b) => a.id - b.id).map((data, index) => (
                                    <div className={'col-6 col-lg-2 '} key={index}>
                                        <div className={'card card-in-home position-relative overflow-hidden'} style={{
                                            width: '100%',
                                            height: 200,
                                            background: data.background_style,
                                            padding: 0
                                        }}>
                                            <div style={{padding: '14px 16px'}}>
                                                <h6 style={{fontSize: 18, color: "white",overflow:"hidden",
                                                    textOverflow:"ellipsis", paddingBottom:14,
                                                    whiteSpace:"nowrap"}}>{data.name}</h6>
                                                <div onClick={() => openApp(data)}>
                                                    <button className={'btn btn-in-home text-light'}
                                                            style={{background: data.button_style}}>เลือก >
                                                    </button>
                                                </div>
                                            </div>
                                            {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                                            <img src={data.full_picture_path} alt="no image" className={''}
                                                 style={{bottom: 0, right: 0, left: 0,objectFit:"cover"}} height={117} width={'100%'}/>
                                        </div>
                                    </div>
                                ))
                            ):(
                                <div className='d-flex justify-content-center align-items-center text-center' style={{height:'50vh'}}>
                                    <div style={{ }}>
                                        {/* eslint-disable-next-line react/jsx-pascal-case */}
                                        <Longan_tree/>
                                        <h2 style={{fontSize:18}}>ไม่พบข้อมูลแอปพลิเคชัน</h2>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                </Layout>
                {userType === 'FARMER' ?  <FooterFarmer/> : <Footer/>}

                {showModal && <ModalCheckFarm modalIsOpen={showModal}
                                              closeModal={()=>setShowModal(false)}
                /> }

            </div>
        </>
    )
}

export default Application;
