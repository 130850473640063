import {Line} from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import {th} from 'date-fns/locale'
import zoomPlugin from 'chartjs-plugin-zoom';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeSeriesScale,
  TimeScale
} from 'chart.js'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeSeriesScale,
  TimeScale,
  zoomPlugin
)


const LineChart = ({datasets}) => {

  const data = {
    datasets: datasets
  };

  const lineOptions = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      mode: "x",
      intersect: false
    },
    scales: {
      xAxis: {
        type: 'timeseries', // `time`  vs `timeseries` later in images
        time: {
          unit: 'minute',
        },
        distribution: "linear",
        adapters: {
          date: {
            locale: th,
          },
        },

      },
    },
    plugins: {
      legend: {
        display: true,
        "position": "bottom"
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: false,
          },
          pinch: {
            enabled: false
          },
          mode: 'xy',
        }
      }
    }
  };

  return (
    <div className="App">
      <Line data={data} options={lineOptions} height={300}/>
    </div>
  )
}

export default LineChart;
