import {Modal, ModalBody} from "reactstrap";
import {GoogleMap, Marker, OverlayView, Polygon, useJsApiLoader} from "@react-google-maps/api";
import React from "react";
import '../assets/css/GoogleMap.css'



const libraries = ['places', 'drawing']
const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;


const FarmMap = ({modalIsOpen,closeModal,polygon,location,name}) =>{

    const [LAT, LNG] = location.split(',').map(Number);
    const handlecloseMap = ()=>{
        closeModal()
    }

    const containerStyle = {
        height:'100vh',
        width:'100vw'
    }

    const center = {lat: LAT, lng: LNG};

    let { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: apiKey,
        libraries,
        language:'th'
    })

    const polygonCoordinates = polygon;


    return isLoaded ? (
            <Modal isOpen={modalIsOpen}
                   centered={true}
                   scrollable={true}
                   fade={true}
                   fullscreen={true}>

                <div className={'position-relative'}>
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={20}
                        options={{
                            mapTypeId:'satellite',
                            streetViewControl:false,
                            zoomControl:false
                        }}
                    >
                        <Polygon
                            path={polygonCoordinates}
                            options={{
                                strokeColor: '#FF0000',
                                strokeOpacity: 0.8,
                                strokeWeight: 2,
                                fillColor: '#FF0000',
                                fillOpacity: 0.1,
                                editable: false,
                                clickable: false
                            }}
                        />

                        <OverlayView mapPaneName="markerLayer" position={center}>
                            <div className="marker-label">
                                <div style={{color: 'black', fontSize: 16}} className="mb-2">{name}</div>
                                <div style={{fontSize: 12, color: '#727178'}}>{location}</div>
                            </div>
                        </OverlayView>

                        <Marker position={center}/>
                    </GoogleMap>

                    <div className={'position-absolute d-flex justify-content-center w-100'} style={{bottom:100,zIndex:999}}>
                        <div style={{width:'50%'}}>
                            <button onClick={()=>handlecloseMap()} className={'btn btn-primary '}>ปิด</button>
                        </div>
                    </div>

                </div>
            </Modal>
        ) : <Modal isOpen={modalIsOpen} centered={true} scrollable={true} fade={true}>
                <ModalBody>
                    <div className="text-center main-green-color p-3">
                        <h5>ไม่สามารถโหลดข้อมูลแผนที่ได้</h5>
                        <h5>กรุณาติดต่อผู้ดูแลระบบ</h5>
                    </div>
                </ModalBody>
            </Modal>
}

export default FarmMap