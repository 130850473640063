import HeaderFarmer from "./layouts/HeaderFarmer";
import Layout from "./layouts/Layout";
import Footer from "./layouts/Footer";
import {Link, useNavigate} from "react-router-dom";
import {store} from "../redux/store";
import {useEffect, useState} from "react";
import {getAppLists} from "../api/ApplicationAPI";
import {dismissPopup, showProgress} from "../components/AlertDialogs";
import FooterFarmer from "./layouts/FooterFarmer";
import {getUserById} from "../api/AuthApi";
import Longan_tree from "../assets/images/Longan_tree";
import ModalCheckFarm from "./farm/ModalCheckFarm";
import {getFarmList} from "../api/FarmsApi";
import Flower from "../assets/icons/Flower.js";
import Pageline from "../assets/icons/Pageline";
import Seedling from "../assets/icons/Seedling";
import Trillium from "../assets/icons/Trillium";
import Olive from "../assets/icons/Olive";
import Basket from "../assets/icons/Basket";


function Predict() {

    // eslint-disable-next-line no-unused-vars
    const [applications, setApplication] = useState([])
    const [userId, setUserId] = useState();
    const [userType, setUserType] = useState();
    const navigate = useNavigate();
    const [user, setUser] = useState({});
    const [showModal, setShowModal] = useState(false);
    const types = [
        {name:'ตัดแต่งกิ่ง',IconColor:'linear-gradient(180deg, #DB6800 0%, #ED7405 100%)',NumberIcon:'#FBA250',nameColor:'#EB7100',btnBackgroundColor:'#FFEEDF',btnColor:'#EB7100',icon:<Pageline/>},
        {name:'ใบแก่',IconColor:'linear-gradient(180deg, #487435 0%, #53853D 100%)',NumberIcon:'#93B784',nameColor:'#53853D',btnBackgroundColor:'#EEFCE8',btnColor:'#53853D',icon:<Seedling/>},
        {name:'ชักนำช่อดอก',IconColor:'linear-gradient(180deg, #C18E0A 0%, #E4AC1D 100%)',NumberIcon:'#F2D262',nameColor:'#C19700',btnBackgroundColor:'#FFF8D1',btnColor:'#C19700',icon:<Trillium/>},
        {name:'แทงช่อดอก',IconColor:'linear-gradient(180deg, #CF3942 0%, #DC565E 100%)',NumberIcon:'#FDADB0',nameColor:'#CB262D',btnBackgroundColor:'#FFE7E7',btnColor:'#CB262D',icon:<Flower/>},
        {name:'ติดผล',IconColor:'linear-gradient(180deg, #889000 0%, #AAB40A 100%)',NumberIcon:'#C8CC71',nameColor:'#82881D',btnBackgroundColor:'#F1F3C8',btnColor:'#82881D',icon:<Olive/>},
        {name:'เก็บเกี่ยวผลผลิต',IconColor:'linear-gradient(180deg, #189098 0%, #2DB7C0 100%)',NumberIcon:'#78C4C9',nameColor:'#0DA1AB',btnBackgroundColor:'#D5FAFD',btnColor:'#2DB7C0',icon:<Basket/>},
    ]

    // eslint-disable-next-line no-unused-vars
    const [delayedIndex, setDelayedIndex] = useState(null);


    const [selBtn0,setSelBtn0] = useState('#fff')
    const [selBtn1,setSelBtn1] = useState('#fff')
    const [selBtn2,setSelBtn2] = useState('#fff')
    const [selBtn3,setSelBtn3] = useState('#fff')
    const [selBtn4,setSelBtn4] = useState('#fff')
    const [selBtn5,setSelBtn5] = useState('#fff')
    // eslint-disable-next-line no-unused-vars
    const [selBtn6,setSelBtn6] = useState('#fff')

    const handleButtonClick = (index) => {
        setDelayedIndex(index);
        // Update the state based on the index of the button clicked
        switch (index) {
            case 0: setSelBtn0('#FFE1C7'); break;
            case 1: setSelBtn1('#DCF0D3'); break;
            case 2: setSelBtn2('#FCF1B7'); break;
            case 3: setSelBtn3('#FCDADA'); break;
            case 4: setSelBtn4('#EBEEB2'); break;
            case 5: setSelBtn5('#C3F4F8'); break;
            default: break;
        }

        setTimeout(() => {
            window.location.href = `/application/${index + 1}`;
            setDelayedIndex(null);
        }, 500);
    };



    useEffect(() => {
        getUserAndApp()
    }, [])

    function getUserAndApp() {
        setUserType(store.getState().app.user.type)
        const user_id = store.getState().app.user.id
        setUserId(user_id)
        showProgress('')
        getAppLists('')
            .then(res => {
                const data = res.data;
                const filteredData = Array.isArray(data) ? data.filter(item => item.show_status === true) : [];
                setApplication(filteredData)
            }).finally(()=>{
            getUserById(user_id).then(res => {
                const datauser = res.data.data
                setUser(datauser)
            }).finally(()=>{
                dismissPopup()
            })
        })
        if (store.getState().app.user.type === 'FARMER'){
            getFarmList(user_id,'').then(res=>{
                if (res.data && res.data.success){
                    if(res.data.data.length <= 0){
                        setShowModal(true)
                    }
                }
            })

        }
    }

    return (
        <>
            <div className={'Container'}>
                <HeaderFarmer showSetting={true} HeadImage={true} showRight={true} />
                <Layout>
                    {/*สวัสดี นาย xxx xxx จำนวนสวน x สวน*/}
                    <div className={'py-2 px-3 d-flex justify-content-between align-items-center bg-second2'} style={{borderRadius: 20}}>
                        <div className={'d-flex'}>
                            <div className={'me-2 profileImage'}>
                                <img src={user.full_picture_path} alt="" width={45} height={45} style={{borderRadius: 100}}/>
                            </div>
                            <div className={'d-grid align-items-center'}>
                                <h6 className={'p-0 m-0'}
                                    style={{
                                        overflow:"hidden",
                                        textOverflow:"ellipsis",
                                        whiteSpace:"nowrap"
                                    }}
                                >สวัสดีคุณ {user.name}
                                </h6>
                                {user.type === 'FARMER' ? (
                                    <div className={'text-muted p-0 m-0'} style={{fontSize:12}}>
                                        {user.count_of_farm === 0 ? `ไม่มีสวน` : `จำนวนสวน ${user.count_of_farm} สวน`}
                                        &nbsp;
                                        <Link to={'/home'} className={'text-decoration-none'}>รายละเอียด</Link>
                                    </div>
                                ) : <div className={'text-muted p-0 m-0'} style={{fontSize:12}}>ผู้ดูแลระบบ</div>}

                            </div>
                        </div>

                        <div onClick={()=> navigate(`/user/${userId}`)}>
                            <i className="fa-solid fa-pen icon bg-second text-color-primary text-sm" ></i>
                        </div>
                    </div>
                    {/*หัวเรื่อง ระยะพัฒนาการ*/}
                        <div>
                            <div className="mt-3 title">
                                ระยะพัฒนาการ
                            </div>
                            <div className="mt-3">
                                {
                                    types.length >= 0 ? 
                                        types.map((type,index) =>(
                                            <div className={'d-flex align-items-center mb-3'} key={index}>
                                                <div style={{height:'50px',background:type.IconColor,borderRadius:'14px 0px 0px 14px',width:54} } className={'d-flex align-items-center justify-content-center'}>
                                                    <div>
                                                        {type.icon}
                                                    </div>
                                                </div>
                                                <button className={'btn p-0 d-flex justify-content-between align-items-center'}
                                                        style={{height:'50px',width:'100%',borderRadius:'0px 14px 14px 0px', boxShadow: '0px 2px 4px -2px rgba(0, 0, 0, 0.05), 0px 4px 6px -1px rgba(0, 0, 0, 0.10)',
                                                            background: index === 0 ? selBtn0 : index === 1 ? selBtn1 : index=== 2 ? selBtn2: index=== 3 ? selBtn3: index=== 4 ? selBtn4 : index=== 5 ? selBtn5: selBtn6
                                                }}
                                                            onClick={()=>handleButtonClick(index)}
                                                >
                                                    <div className={'d-flex align-items-center'}>
                                                        <div style={{fontSize:32,marginRight:'10',marginLeft:10,color:type.NumberIcon}} className={'fw-bold me-2'}>{index+1}</div>
                                                        <div style={{fontSize:20,color:type.nameColor}}>{type.name}</div>
    
                                                    </div>
                                                    <div className={'me-2 d-flex justify-content-center align-items-center'} style={{background:type.btnBackgroundColor,borderRadius:100,width:25,height:25}}>
                                                        <i className={'fa-solid fa-angle-right'} style={{fontSize:15}} color={type.btnColor}></i>
                                                    </div>
    
                                                </button>
                                            </div>
                                        ))
                                        : (
                                            <div className='d-flex justify-content-center align-items-center text-center' style={{height:'50vh'}}>
                                                <div style={{ }}>
                                                    {/* eslint-disable-next-line react/jsx-pascal-case */}
                                                    <Longan_tree/>
                                                    <h2 style={{fontSize:18}}>ไม่พบข้อมูลแอปพลิเคชัน</h2>
                                                </div>
                                            </div>
                                        )
                                }
                            </div>
                        </div>
                </Layout>
                {userType === 'FARMER' ?  <FooterFarmer/> : <Footer/>}

                {showModal && <ModalCheckFarm modalIsOpen={showModal}
                                              closeModal={()=>setShowModal(false)}
                /> }

            </div>
        </>
    )


}

export default Predict;