import icon from './icon.png'


function IconBrand() {
    return (
        <>
            <img src={icon} alt=""/>&nbsp;&nbsp;
            <svg xmlns="http://www.w3.org/2000/svg" width="217" height="30" viewBox="0 0 217 30" fill="none">
                <path d="M194.956 11.3694C194.956 9.42539 194.917 8.13264 194.839 7.49112H198.105C198.202 8.26872 198.251 9.11435 198.251 10.028H198.309C198.62 9.15323 199.184 8.46311 200 7.95768C200.817 7.45224 201.769 7.19952 202.858 7.19952C203.908 7.19952 204.802 7.4328 205.541 7.89936C206.279 8.34647 206.775 8.96855 207.028 9.76558C207.417 8.96855 208.01 8.34647 208.807 7.89936C209.604 7.4328 210.498 7.19952 211.489 7.19952C213.103 7.19952 214.366 7.7244 215.28 8.77415C216.213 9.80446 216.68 11.2333 216.68 13.0606V22.071H212.976V13.4689C212.976 12.4386 212.753 11.6415 212.306 11.0778C211.859 10.4946 211.246 10.203 210.469 10.203C209.613 10.203 208.923 10.5043 208.398 11.1069C207.893 11.6901 207.64 12.4872 207.64 13.498V22.071H203.966V13.5855C203.966 12.5163 203.752 11.6901 203.325 11.1069C202.897 10.5043 202.294 10.203 201.517 10.203C200.681 10.203 199.991 10.5237 199.446 11.1653C198.921 11.7873 198.659 12.5941 198.659 13.5855V22.071H194.956V11.3694Z" fill="#0E572B" />
                <path d="M183.541 11.0486C183.541 9.39623 183.512 8.2104 183.454 7.49112H186.982C187.06 8.24928 187.099 9.10463 187.099 10.0572V10.3488H187.215C187.585 9.39623 188.236 8.63807 189.169 8.07432C190.102 7.49112 191.171 7.19952 192.376 7.19952C192.61 7.19952 192.901 7.22868 193.251 7.287V10.2321C193.174 10.1933 192.94 10.1738 192.551 10.1738C191.618 10.1738 190.744 10.4265 189.927 10.932C189.13 11.4374 188.489 12.1178 188.003 12.9732C187.517 13.8091 187.274 14.713 187.274 15.685V22.071H183.541V11.0486Z" fill="#0E572B" />
                <path d="M173.507 22.3626C171.155 22.3626 169.318 21.6919 167.996 20.3506C166.674 19.0092 166.013 17.1527 166.013 14.7811C166.013 12.4094 166.674 10.5529 167.996 9.21155C169.318 7.8702 171.155 7.19952 173.507 7.19952C175.859 7.19952 177.696 7.8702 179.018 9.21155C180.36 10.5529 181.03 12.4094 181.03 14.7811C181.03 17.1527 180.36 19.0092 179.018 20.3506C177.696 21.6919 175.859 22.3626 173.507 22.3626ZM173.507 19.5924C174.693 19.5924 175.626 19.1647 176.306 18.3094C176.987 17.454 177.327 16.2779 177.327 14.7811C177.327 13.2842 176.987 12.1081 176.306 11.2527C175.626 10.3974 174.693 9.9697 173.507 9.9697C172.321 9.9697 171.388 10.3974 170.708 11.2527C170.047 12.1081 169.716 13.2842 169.716 14.7811C169.716 16.2779 170.047 17.454 170.708 18.3094C171.388 19.1647 172.321 19.5924 173.507 19.5924Z" fill="#0E572B" />
                <path d="M157.968 10.028H155.576V7.49112H157.968V5.74153C157.968 4.32242 158.415 3.20463 159.309 2.38816C160.203 1.57169 161.428 1.16345 162.983 1.16345C163.702 1.16345 164.373 1.28009 164.995 1.51337C165.617 1.72721 166.123 2.02852 166.511 2.41732L165.228 4.63346C164.995 4.41962 164.723 4.25438 164.412 4.13775C164.101 4.02111 163.79 3.96279 163.479 3.96279C162.915 3.96279 162.468 4.14746 162.137 4.51682C161.826 4.88618 161.671 5.39161 161.671 6.03313V7.49112H165.782V10.028H161.671V22.071H157.968V10.028Z" fill="#0E572B" />
                <path d="M150.767 22.3626C149.271 22.3626 148.104 21.9543 147.268 21.1379C146.432 20.3214 146.014 19.1744 146.014 17.697V10.2613H143.361V7.49111H146.014V3.7295L149.63 2.67975H149.747V7.49111H153.975V10.2613H149.747V17.143C149.747 17.9789 149.941 18.5912 150.33 18.98C150.719 19.3688 151.312 19.5632 152.109 19.5632C152.867 19.5632 153.576 19.4466 154.237 19.2133L154.412 21.7502C153.538 22.1585 152.323 22.3626 150.767 22.3626Z" fill="#0E572B" />
                <path d="M133.438 22.3626C132.485 22.3626 131.63 22.1682 130.872 21.7794C130.114 21.3712 129.521 20.8171 129.093 20.1173C128.665 19.398 128.451 18.601 128.451 17.7262C128.451 16.2293 129.044 15.0824 130.23 14.2853C131.416 13.4883 133.107 13.0898 135.304 13.0898H137.578V12.4774C137.578 11.6998 137.345 11.1069 136.879 10.6987C136.431 10.271 135.79 10.0572 134.954 10.0572C134.079 10.0572 133.243 10.2127 132.446 10.5237C131.649 10.8348 130.998 11.2624 130.493 11.8068L128.86 9.64894C129.657 8.89079 130.6 8.29788 131.688 7.8702C132.777 7.42308 133.904 7.19952 135.071 7.19952C137.015 7.19952 138.541 7.65636 139.649 8.57003C140.757 9.4837 141.311 10.7473 141.311 12.3608V19.0092C141.311 19.4563 141.34 19.9812 141.398 20.5838C141.476 21.1865 141.564 21.6822 141.661 22.071H138.366C138.249 21.8183 138.162 21.5753 138.103 21.342C138.045 21.0893 137.996 20.7491 137.957 20.3214H137.899C136.791 21.6822 135.304 22.3626 133.438 22.3626ZM134.342 19.5633C135.275 19.5633 136.043 19.33 136.645 18.8634C137.267 18.3774 137.578 17.7651 137.578 17.0264V15.5684H135.187C134.196 15.5684 133.428 15.7531 132.884 16.1224C132.359 16.4918 132.096 17.0264 132.096 17.7262C132.096 18.3094 132.291 18.7662 132.68 19.0967C133.088 19.4077 133.642 19.5633 134.342 19.5633Z" fill="#0E572B" />
                <path d="M124.288 22.3626C123.044 22.3626 122.091 22.0321 121.43 21.3712C120.789 20.6908 120.468 19.7188 120.468 18.4552V0.434479H124.171V18.5135C124.171 18.9412 124.278 19.262 124.492 19.4758C124.725 19.6702 125.066 19.7674 125.513 19.7674C125.96 19.7674 126.514 19.641 127.175 19.3883L127.729 21.3712C126.854 22.0321 125.707 22.3626 124.288 22.3626Z" fill="#0E572B" />
                <path d="M103.864 11.3694C103.864 9.79474 103.825 8.50199 103.747 7.49112H107.246C107.305 8.07432 107.334 8.71583 107.334 9.41567H107.392C107.878 8.71583 108.51 8.17152 109.287 7.78272C110.065 7.39392 110.93 7.19952 111.883 7.19952C113.729 7.19952 115.187 7.87992 116.257 9.24071C117.326 10.6015 117.86 12.458 117.86 14.8102C117.86 17.1624 117.316 19.0092 116.227 20.3506C115.158 21.6919 113.671 22.3626 111.766 22.3626C110.833 22.3626 110.007 22.2071 109.287 21.896C108.568 21.585 108.034 21.1476 107.684 20.5838H107.596V29.1277H103.864V11.3694ZM110.95 19.5924C111.98 19.5924 112.767 19.1745 113.311 18.3385C113.856 17.4832 114.128 16.2682 114.128 14.6936C114.128 11.6026 113.088 10.0572 111.008 10.0572C109.997 10.0572 109.171 10.3585 108.529 10.9611C107.907 11.5443 107.596 12.3219 107.596 13.2939V16.2974C107.596 17.2305 107.917 18.0178 108.558 18.6593C109.2 19.2814 109.997 19.5924 110.95 19.5924Z" fill="#0E572B" />
                <path d="M80.4518 11.3402C80.4518 9.57118 80.413 8.28816 80.3352 7.49112H83.8344C83.9316 8.30759 83.9802 9.15323 83.9802 10.028H84.0677C84.4176 9.17267 85.0299 8.49227 85.9047 7.98684C86.7989 7.46196 87.7904 7.19952 88.879 7.19952C90.5508 7.19952 91.8144 7.76328 92.6698 8.89079C93.5251 10.0183 93.9528 11.6804 93.9528 13.8771V22.071H90.2495V13.8479C90.2495 12.5844 90.0454 11.661 89.6372 11.0778C89.2289 10.4946 88.5777 10.203 87.6835 10.203C86.6532 10.203 85.8075 10.5723 85.1466 11.311C84.505 12.0303 84.1843 12.9634 84.1843 14.1104V22.071H80.4518V11.3402Z" fill="#0E572B" />
                <path d="M69.5083 22.3626C68.5557 22.3626 67.7004 22.1682 66.9422 21.7794C66.1841 21.3712 65.5912 20.8171 65.1635 20.1173C64.7358 19.398 64.522 18.601 64.522 17.7262C64.522 16.2293 65.1149 15.0824 66.3007 14.2853C67.4865 13.4883 69.1778 13.0898 71.3745 13.0898H73.649V12.4774C73.649 11.6998 73.4157 11.1069 72.9491 10.6987C72.502 10.271 71.8605 10.0572 71.0246 10.0572C70.1498 10.0572 69.3139 10.2127 68.5169 10.5237C67.7198 10.8348 67.0686 11.2624 66.5632 11.8068L64.9302 9.64894C65.7272 8.89079 66.6701 8.29788 67.7587 7.8702C68.8473 7.42308 69.9748 7.19952 71.1412 7.19952C73.0852 7.19952 74.6112 7.65636 75.7193 8.57003C76.8274 9.4837 77.3814 10.7473 77.3814 12.3608V19.0092C77.3814 19.4563 77.4106 19.9812 77.4689 20.5838C77.5467 21.1865 77.6341 21.6822 77.7313 22.071H74.4363C74.3196 21.8183 74.2322 21.5753 74.1739 21.342C74.1155 21.0893 74.0669 20.7491 74.028 20.3214H73.9697C72.8617 21.6822 71.3745 22.3626 69.5083 22.3626ZM70.4122 19.5633C71.3454 19.5633 72.1132 19.33 72.7159 18.8634C73.3379 18.3774 73.649 17.7651 73.649 17.0264V15.5684H71.2579C70.2664 15.5684 69.4986 15.7531 68.9543 16.1224C68.4294 16.4918 68.1669 17.0264 68.1669 17.7262C68.1669 18.3094 68.3613 18.7662 68.7501 19.0967C69.1584 19.4077 69.7124 19.5633 70.4122 19.5633Z" fill="#0E572B" />
                <path d="M62.2275 7.49112V21.4878C62.2275 23.9567 61.6152 25.8132 60.3905 27.0573C59.1658 28.3209 57.3481 28.9527 54.9376 28.9527C53.8101 28.9527 52.7409 28.8166 51.73 28.5445C50.7386 28.2723 49.8541 27.8835 49.0765 27.3781L50.3304 24.8412C51.0302 25.1911 51.7689 25.4632 52.5465 25.6576C53.3241 25.8715 54.0628 25.9784 54.7626 25.9784C56.0845 25.9784 57.0663 25.5604 57.7078 24.7245C58.3493 23.9081 58.67 22.6639 58.67 20.9921V20.5255C58.2812 21.0504 57.7466 21.4586 57.0663 21.7502C56.3859 22.0224 55.5791 22.1585 54.646 22.1585C52.6243 22.1585 51.0496 21.4975 49.9221 20.1756C48.814 18.8537 48.26 16.9875 48.26 14.5769C48.26 12.2442 48.7946 10.4363 49.8638 9.15323C50.933 7.85076 52.4396 7.19952 54.3836 7.19952C56.4053 7.19952 57.873 7.90908 58.7867 9.32819H58.845L59.1366 7.49112H62.2275ZM58.5242 13.8771C58.5242 12.6135 58.2521 11.6512 57.7078 10.9903C57.1635 10.3099 56.3761 9.9697 55.3458 9.9697C54.2572 9.9697 53.4213 10.3779 52.8381 11.1944C52.2549 11.9914 51.9633 13.1481 51.9633 14.6644C51.9633 16.1807 52.2452 17.3277 52.8089 18.1053C53.3921 18.8829 54.2378 19.2717 55.3458 19.2717C56.3761 19.2717 57.1635 18.9703 57.7078 18.3677C58.2521 17.7456 58.5242 16.8708 58.5242 15.7433V13.8771Z" fill="#0E572B" />
                <path d="M32.4123 11.3402C32.4123 9.57118 32.3734 8.28816 32.2957 7.49112H35.7948C35.892 8.30759 35.9406 9.15323 35.9406 10.028H36.0281C36.378 9.17267 36.9904 8.49227 37.8652 7.98684C38.7594 7.46196 39.7508 7.19952 40.8395 7.19952C42.5113 7.19952 43.7749 7.76328 44.6302 8.89079C45.4856 10.0183 45.9133 11.6804 45.9133 13.8771V22.071H42.21V13.8479C42.21 12.5844 42.0058 11.661 41.5976 11.0778C41.1894 10.4946 40.5381 10.203 39.6439 10.203C38.6136 10.203 37.768 10.5723 37.107 11.311C36.4655 12.0303 36.1447 12.9634 36.1447 14.1104V22.071H32.4123V11.3402Z" fill="#0E572B" />
                <path d="M22.5827 22.3626C20.2305 22.3626 18.3934 21.6919 17.0715 20.3506C15.7496 19.0092 15.0886 17.1527 15.0886 14.7811C15.0886 12.4094 15.7496 10.5529 17.0715 9.21155C18.3934 7.8702 20.2305 7.19952 22.5827 7.19952C24.9349 7.19952 26.772 7.8702 28.0939 9.21155C29.4352 10.5529 30.1059 12.4094 30.1059 14.7811C30.1059 17.1527 29.4352 19.0092 28.0939 20.3506C26.772 21.6919 24.9349 22.3626 22.5827 22.3626ZM22.5827 19.5924C23.7685 19.5924 24.7016 19.1647 25.382 18.3094C26.0624 17.454 26.4026 16.2779 26.4026 14.7811C26.4026 13.2842 26.0624 12.1081 25.382 11.2527C24.7016 10.3974 23.7685 9.9697 22.5827 9.9697C21.3968 9.9697 20.4637 10.3974 19.7833 11.2527C19.1224 12.1081 18.7919 13.2842 18.7919 14.7811C18.7919 16.2779 19.1224 17.454 19.7833 18.3094C20.4637 19.1647 21.3968 19.5924 22.5827 19.5924Z" fill="#0E572B" />
                <path d="M0.219971 1.65918H4.41897V18.7176H14.0417V22.071H0.219971V1.65918Z" fill="#0E572B" />
            </svg>
        </>
    )
}




export default IconBrand;