import ImageGallery from "react-image-gallery";
import 'react-image-gallery/styles/css/image-gallery.css'
import {useEffect, useState} from "react";
import {getBugsPictureByStation} from "../../api/WeatherApi";
import {useSelector} from "react-redux";

const BugsPicture = () => {
  const user = useSelector(state => {
    return state && state.app && state.app.user
  })

  const [datas, setData] = useState([])
  /*const images = [
    {
      original: "https://picsum.photos/id/1018/1000/600/",
      thumbnail: "https://picsum.photos/id/1018/250/150/",
    },
    {
      original: "https://picsum.photos/id/1015/1000/600/",
      thumbnail: "https://picsum.photos/id/1015/250/150/",
    },
    {
      original: "https://picsum.photos/id/1019/1000/600/",
      thumbnail: "https://picsum.photos/id/1019/250/150/",
    },
  ];*/

  useEffect(() => {
    if(user.station_id) {
      getPicture()
    }
  }, []);

  const getPicture = () => {
    getBugsPictureByStation(user.station_id)
      .then(res => {
        if(res.data && res.data.success) {
          setData(res.data.data);
        }
      })
  }

  /*const datas = [
    {
      date: '12/02/2024',
      images
    },
    {
      date: '11/02/2024',
      images
    }
  ];*/


  return (
    <div className="mt-3">
      <div style={{fontSize: 20, fontWeight: 600, color: '#0E572B'}}>Picture</div>
      {
        Object.keys(datas) && Object.keys(datas).length > 0 ?
          Object.keys(datas).map((date) => (
          <div className="mt-4">
            <div>{date}</div>
            <div className="mt-3 p-2" style={{border: '1px solid lightgrey'}}>
              <ImageGallery items={datas[date].images}
                            showNav={false}
                            showThumbnails={false}
                            showFullscreenButton={true}
                            useBrowserFullscreen={false}
                            showPlayButton={false}
                            showBullets={true}
                // autoPlay={false}
              />
            </div>
          </div>
        )) : 'ไม่พบข้อมูลรูปแมลง'
      }
    </div>
  )
}

export default BugsPicture;
