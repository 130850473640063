import {get, getBlob, post} from "./ApiComponent";

export const getWeatherData = (station_id) => {
  return get(`/weather_data/station/${station_id}`);
}

export const getSensorsByStation = (station_id) => {
  return get(`station/${station_id}/sensor`);
}

export const getThingIOTByStation = (station_id) => {
  return get(`station/${station_id}/thing`);
}

export const controlSensorById = (station_id, sensor_data) => {
  return post(`station/${station_id}/sensor/${sensor_data.id}`, sensor_data);
}

export const getStationById = (station_id) => {
  return get(`station/${station_id}`);
}

export const getBugsPictureByStation = (station_id) => {
  return get(`station/${station_id}/bugs_picture`);
}

export const getSensorLogForChart = (station_id,filter) => {
  return get(`/station/${station_id}/chart`,filter);
}

export const exportSensorLogApi = (station_id,filter) => {
  return getBlob(`/station/${station_id}/sensor/export`,filter);
}

export const exportControllerLogApi = (station_id,filter) => {
  return getBlob(`/station/${station_id}/controller/export`,filter);
}