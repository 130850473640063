import {get, post, remove} from './ApiComponent'


export const getAppLists = (data, is_check_status=false) => {
    return get(`get_apps?search=${data}&is_check_status=${JSON.parse(is_check_status)}`)
}

// export const getAppLists = (data) => {
//     return get(`get_apps?search=${data}`)
// }

export const addApp = (data, selectStatus) => {
    let formData = new FormData();
    for(let key in data) {
        if(data[key] != null && data[key] !== '') {
            formData.append(key, data[key]);
        }
    }
    formData.append('selectStatus', JSON.stringify(selectStatus));
    return post('add-app',formData)
}

export const getAppDetail = (id) => {
    return get(`/get-app-detail/${id}`)
}

export const EditApp = (data, selectStatus) => {
    let formData = new FormData();
    for(let key in data) {
        if(data[key] != null && data[key] !== '') {
            formData.append(key, data[key]);
        }
    }
    formData.append('selectStatus', JSON.stringify(selectStatus));
    return post(`/edit_app/${data.id}`,formData)
}


export const showApp = (id,data) => {
    let formData = new FormData();
    for(let key in data) {
        if(data[key] != null && data[key] !== '') {
            formData.append(key, data[key]);
        }
    }
    return post(`/status/${id}`,formData)
}

export const deleteApp = (id) => {
    return remove(`/delete_app/${id}`)
}

export const getRef = () => {
    return get(`refresh_token`)
}

export const generateOnetimeToken = (id) => {
    return get(`generate_onetime_token/${id}`)
}
