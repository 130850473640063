import HeaderFarmer from "../layouts/HeaderFarmer";
import '../../assets/css/WeatherStation.css'
import {useEffect, useState} from "react";
import {getStationById, getWeatherData} from "../../api/WeatherApi";
import Layout from "../layouts/Layout";
import FooterFarmer from "../layouts/FooterFarmer";
import {dismissPopup, showProgress} from "../../components/AlertDialogs";
import WeatherStation from "./WeatherStation";
import Sensor from "./Sensor";
import {useSelector} from "react-redux";
import Footer from "../layouts/Footer";
import BugsPicture from "./BugsPicture";
import {getStationList} from "../../api/FarmerApi";
import Select from "react-select";

const Index = () => {
    const [data, setData] = useState(null)
    const [stationData, setStationData] = useState(null)
    const [stationOptions, setStationOptions] = useState([]);
    const [stationId, setStationId] = useState(null)
    const user = useSelector(state => {
        return state && state.app && state.app.user
    })

    useEffect(() => {
        fetchStationOptions()
        if (user.id && user.station_id) {
            setStationId(user.station_id)
        }
    }, []);

    const getData = () => {
        showProgress('')
        getWeatherData(stationId)
            .then(res => {
                if (res?.data?.success) {
                    setData(res.data.data)
                }
            }).finally(() => {
            dismissPopup()
        })
    }

    const getStationDetail = () => {
        getStationById(stationId)
            .then(res => {
                if (res.data && res.data.success) {
                    setStationData(res.data.data)
                }
            })
    }

    const fetchStationOptions = () => {
        getStationList('')
            .then(res => {
                if (res && res.data.success) {
                    let stations = [];
                    res.data.data.map((item) => {
                        stations.push({
                            value: item.ref_id,
                            label: item.name_th
                        })
                    })
                    setStationOptions(stations)
                }
            })
    }
    const changeStation = (selectedOption) => {
        setStationId(selectedOption.value)
    }

    useEffect(() => {
        if (!stationId) {
            return
        }
        getData()
        getStationDetail()
    }, [stationId]);

    return (
        <div className="Container">
            <HeaderFarmer showSetting={true} HeadImage={true} showRight={false} showAddFarm={true}/>
            <Layout>
                {
                    user.type === 'ADMIN' &&
                    (<div className={'form-group mb-3 station-selected'}>
                        <label htmlFor="station_id" className={'form-label'}>แปลงวิจัย</label>
                        <Select
                            className="form-select-2"
                            onChange={changeStation}
                            options={stationOptions}
                            placeholder="กรุณาเลือกแปลงวิจัย"
                        />
                    </div>)
                }
                <WeatherStation data={data} stationData={stationData}/>

                <Sensor stationId={stationId}/>

                <BugsPicture/>
            </Layout>

            {
                user.type === 'ADMIN' ? <Footer/> : <FooterFarmer/>
            }
        </div>
    )
}

export default Index;
