import React, {useEffect, useState} from "react";
import Layout from '../views/layouts/Layout'
import {Link, useNavigate} from "react-router-dom";
import HeaderFarmer from "./layouts/HeaderFarmer";
import Footer from "./layouts/Footer";
import {alertError, alertSuccess, confirmPopupText, dismissPopup, showProgress} from "../components/AlertDialogs";
import {addApp, getRef} from "../api/ApplicationAPI";
import imageEmtry from '../assets/images/image_emtry.png'
import accept_icon from '../assets/icons/accept_icon.png'
import Pageline from "../assets/icons/Pageline";
import Seedling from "../assets/icons/Seedling";
import Trillium from "../assets/icons/Trillium";
import Flower from "../assets/icons/Flower";
import Olive from "../assets/icons/Olive";
import Basket from "../assets/icons/Basket";
import ValidateMimeType from "../helper/ValidateMimeType";
function AddApp(){
    useEffect(()=>{
        hendlieRefresh();
        // window.location.href = 'http://localhost:3001/verify_web_portal'
    }, [])
    const [app, setApp] = useState({
        name:'',
        url: '',
        picture:'',
        background_style: '#617680',
        button_style: '#77929f',
        token: ''
    });
    const [file_preview, setFilePreview] = useState(null);
    const navigate = useNavigate();
    const [confirmtoken, setConfirmToken] = useState(false);
    const [selectStatus, setSelectStatus] = useState(false);



    const types = {
        1: ['ตัดแต่งกิ่ง', '#EB7100', '#FFEEDF', <Pageline width='20' height='20' color='#EB7100'/>],
        2: ['ใบแก่', '#53853D', '#EEFCE8', <Seedling width='20' height='20' color='#53853D'/>],
        3: ['ชักนำช่อดอก', '#C19700', '#FFF8D1', <Trillium width='20' height='20' color='#C19700'/>],
        4: ['แทงช่อดอก', '#CB262D', '#FFE7E7', <Flower width='20' height='20' color='#CB262D'/>],
        5: ['ติดผล', '#82881D', '#F1F3C8', <Olive width='20' height='20' color='#82881D'/>],
        6: ['เก็บเกี่ยวผลผลิต', '#0DA1AB', '#C3F4F8',<Basket width='20' height='20' color='#0DA1AB'/>],
    };

    const [count, setCount] = useState(0)

    const handleButtonClick = (id) => {
        setSelectStatus((prevStatus) => {
            const updatedStatus = { ...prevStatus };
            updatedStatus[id] = !updatedStatus[id];
            return updatedStatus;
        });
    };


    const handleChangeFile = (e) => {
        let is_valid = ValidateMimeType(e.target.files[0]);
        if (!is_valid) {
            alertError('', 'ไฟล์ต้องเป็นประเภท .png หรือ .jpg เท่านั้น')
            return
        }
        setData('picture', e.target.files[0])

        if (e.target.files && e.target.files[0]) {
            let reader = new FileReader();

            reader.onload = function (ev) {
                setFilePreview(ev.target.result)
            };

            reader.readAsDataURL(e.target.files[0]);
        }
    }


    const handleRemoveFile = () => {
        setData('picture', null)
        setFilePreview(null)
    }

    const setData = (key, value) => {
        setApp(exitingData => ({
            ...exitingData,
            [key]: value
        }))
    }

    const btnRefresh = ()=>{
        if (count >=5 ){
            setConfirmToken(true)
            alertError('เกิดข้อผิดพลาด','สามารถเปลี่ยน Token ได้ไม่เกิน 5 ครั้ง')
        }else{
            setCount(()=>count+1)
        }
        getToken()
    }

    const hendlieRefresh = () => {
        getToken()
    }

    function getToken() {
        getRef()
            .then(res => {
                setData('token', res.data)
            })
            .catch(error => {
                if (error.response && error.response.status === 429) {
                    console.log('hello');
                } else {
                    console.error('Error getting token:', error);
                }
            });

        if (app.token == '[object Object]'){
            alertError('token มีปัญหา','กรุณารีเฟรชหน้าเว็บแล้วลองอีกครั้ง')
        }
    }


    const submit = () => {
        confirmPopupText('บันทึก', 'คุณต้องการบันทึกหรือไม่' ,() =>{
            if (app.token == '[object Object]'){
                alertError('token มีปัญหา','กรุณารีเฟรชหน้าเว็บแล้วลองอีกครั้ง')
            }else{
                if(!app.picture){
                    alertError('เกิดข้อผิดพลาด', 'กรุณาอัปโหลดรูปภาพ');
                }
                else if (app.picture.type !== 'image/jpeg' && app.picture.type !== 'image/png') {
                    alertError('เกิดข้อผิดพลาด', 'อัปโหลดนามสกุล jpeg,png เท่านั้น');
                }
                else{
                    showProgress('')
                    addApp(app, selectStatus)
                        .then((res) => {
                            if(res.data && res.data.success) {
                                alertSuccess('สร้างแอปพลิเคชันสำเร็จ', ' ')
                                setTimeout(() => {
                                    dismissPopup()
                                    navigate('/application')
                                }, 1500)
                            }else {
                                hendlieRefresh()
                                alertError('เกิดข้อผิดพลาด', (res.data && res.data.message) || '')
                            }
                        })
                }
            }

        })
    }


    return (
        <>

            <div className={'Container'}>
                <HeaderFarmer HeadTitle={'เพิ่มแอปพลิเคชัน'} showBack={true}/>
                <Layout>
                    <div className="form-group mb-2">
                        <label className='text-color-gray form-label'>ชื่อ Application</label>
                        <input className='form-control'
                               type="text"
                               placeholder="ระบุชื่อ Application"
                               name={'name'}
                               onChange={(e) => setData(e.target.name, e.target.value, e)}
                               value={(app && app.name) || ''}
                        />
                    </div>
                    <div className={'form-group'}>
                        <label className='text-color-gray form-label'>Token</label>
                        <div className="form-group mb-2">
                            <input className='form-control w-100' type="text" placeholder="" readOnly={true} value={(app && app.token) || ''}/>
                        </div>
                    </div>
                    <div className={'row gx-2'}>
                        <div className={'col-6'}>
                            <button className={'btn btn-warning'} style={{height:40}} onClick={btnRefresh} disabled={confirmtoken}>
                                <i className="fa-solid fa-rotate-left"></i>
                                <span className={'ms-1'}>เปลี่ยน {count}/5</span>
                            </button>
                        </div>
                        <div className={'col-6'}>
                            <button className={'btn btn-primary'} style={{height:40}} onClick={()=>setConfirmToken(true)} disabled={confirmtoken}>
                                <i className="fa-solid fa-check"></i>
                                <span className={'ms-1'}>ยืนยัน</span>
                            </button>
                        </div>
                    </div>
                    <div className={'form-group mb-2'}>
                        <label className={'text-color-gray form-label'}>URL</label>
                        <input className={'form-control'}
                               type="text"
                               placeholder={'ระบุ URL'}
                               name={'url'}
                               onChange={(e) => setData(e.target.name, e.target.value, e)}
                               value={(app && app.url) || ''}
                        />
                    </div>

                    <div className={'form-group'}>
                        <label className={'form-label text-color-gray'}>รูปหน้าปก</label>
                        <div className={'mb-2 overflow-hidden position-relative'} style={{borderRadius:12,border:'1px solid #B4D1C1' ,height:200}}>
                            {file_preview ? (
                                <div className="w-100 h-100">
                                    <button onClick={() => handleRemoveFile()} className={'position-absolute btn-clear'} style={{right:10,top:12}}>
                                        <i className="fa-solid fa-minus icon text-color-danger bg-dan"></i>
                                    </button>
                                    {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                                    <img src={file_preview ? file_preview : ''} alt='No Image' className={'w-100 h-100 object-fit-contain'}/>
                                </div>
                            ):(
                                <>
                                    <div className={'w-100 d-flex justify-content-center align-items-center mt-3'}>
                                        <div className={'p-5 bg-second2 rounded-circle d-flex align-items-center justify-content-center'} style={{height:50,width:50}} >
                                            <i className="fa-solid fa-image text-color-primary" style={{fontSize:30}}></i>
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="custom-file position-relative d-flex justify-content-center">
                                        <button className={'btn btn-warning text-light'} style={{height:40 ,width:'50%'}}>
                                            <i className="fa-solid fa-image"></i>
                                            &nbsp;
                                            <input type={'file'} className={'position-absolute opacity-0'} style={{width:'100%',right:0}}
                                                   onChange={handleChangeFile} accept="image/*"
                                            />
                                            <span style={{fontSize:16}}>อัปโหลดรูปภาพ</span>
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>


                    {/*card preview*/}
                    <div className={'row'}>
                        <label className={'form-label text-color-gray'}>ตัวอย่างการแสดงผล</label>
                        <div className={'col-6'}>
                            <div className={'card card-in-home position-relative overflow-hidden'} style={{
                                width: '100%',
                                height: 200,
                                background: app.background_style,
                                padding: 0
                            }}>
                                <div style={{padding: '14px 15px'}}>
                                    <h6 style={{fontSize: 18, color: "white", marginBottom: 7,overflow:"hidden",
                                        textOverflow:"ellipsis",whiteSpace:"nowrap"}}>
                                        {app.name === '' ? (
                                            'ชื่อ Application'
                                        ) : (
                                            app.name
                                        )}
                                    </h6>
                                    <Link to={app.url} target={"_blank"}>
                                        <button className={'btn btn-in-home text-light'}
                                                style={{background: app.button_style}}>เลือก >
                                        </button>
                                    </Link>
                                </div>
                                <img src={file_preview ? file_preview : imageEmtry} alt={imageEmtry} className={''}
                                     style={{bottom: 0, right: 0, left: 0,objectFit:"cover"}} height={117} width={'100%'}/>
                            </div>
                        </div>
                        {/*input color customize card*/}
                        <div className="col-6">
                            <div className="row">
                                <div className={'col-12'}>
                                    <label className={'form-label'}>เลือกสีพื้นหลัง</label>
                                    <div className={'d-flex justify-content-start'} style={{border:"solid black #ffffff"}}>
                                        <input type="color" className={'w-100 p-0 rounded-0'}
                                               name={'background_style'}
                                               onChange={(e) => setData(e.target.name, e.target.value, e)}
                                               value={(app && app.background_style) || ''}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className={'col-12'}>
                                    <label className={'form-label'}>เลือกสีปุ่ม</label>
                                    <div className={'d-flex justify-content-start'}>
                                        <input type="color" className={'p-0 w-100 rounded-0'}
                                               name={'button_style'}
                                               onChange={(e) => setData(e.target.name, e.target.value, e)}
                                               value={(app && app.button_style) || ''}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <label className={'form-label text-color-gray mt-2'}>ระยะพัฒนาการ</label>
                        <div className='row g-2'>
                            {Object.keys(types).map((typeKey, index) => (
                                <div key={index} className='col-6'>
                                    <div className='d-flex p-2' style={{ background: selectStatus[Number(typeKey)] ? types[typeKey][2] : '', borderRadius: 14 }} onClick={() => handleButtonClick(Number(typeKey))}>
                                        <div className='mx-2'>
                                            <input readOnly={true} id={`checkStatus${index}`} className='form-check-input' type='checkbox' checked={selectStatus[Number(typeKey)]}/>
                                        </div>
                                        <div className='me-2'>{types[typeKey][3]}</div>
                                        <div>
                                            <span style={{ color: types[typeKey][1], fontSize: 12 }}>{types[typeKey][0]}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <button className={'btn btn-primary mt-3 d-flex justify-content-center align-items-center'} onClick={submit}>
                        <img src={accept_icon} alt=""/>&nbsp;
                        <span>ตกลง</span>
                    </button>

                </Layout>
                <Footer/>
            </div>
        </>
    )
}
export default AddApp;
