import {Link, useNavigate} from "react-router-dom";
import {Modal, ModalBody} from "reactstrap";
import {logout} from "../../api/AuthApi";
import {alertError, alertSuccess, showProgress} from "../../components/AlertDialogs";
import {setToken} from "../../api/Api";
import {actionSetAppToken, actionSetUser} from "../../redux/app";
import {useDispatch} from "react-redux";

const ModalCheckFarm = ({modalIsOpen, closeModal}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const handleLogout = () => {
        showProgress('', 'ระบบกำลังนำท่านออกจากระบบ')
        logout()
            .then(res => {
                if(res.data && res.data.success) {
                    // if(user && user.type === 'FARMER') {
                    //     liff.logout()
                    // }
                    setToken(null)
                    dispatch(actionSetAppToken(null))
                    dispatch(actionSetUser(null))
                    navigate('/login')
                    alertSuccess('ออกจากระบบสำเร็จ')
                }else {
                    alertError('ออกจากระบบไม่สำเร็จ')
                }
            })
    }

    return (
        <>
          <Modal isOpen={modalIsOpen} centered={true} scrollable={true} fade={true}>
              <ModalBody className={'position-relative'} style={{height:'50%'}}>
                  <div className={'text-center'}>
                      <div className={'mt-3'}>
                          <h1>ไม่พบข้อมูลสวน</h1>
                          <span style={{fontSize: 18,}}>
                              กรุณาเพิ่มสวนก่อนเข้าใช้งานระบบ
                          </span>
                      </div>
                      <div className="mt-3">
                          <button type="button" className="btn btn-confirm-approve text-white w-100 d-flex justify-content-center align-items-center mb-2"
                                  style={{background: '#0E572B', borderRadius: 50, padding: 13}}
                          >
                              {/* eslint-disable-next-line jsx-a11y/alt-text */}
                              <img src={''} className="me-2" alt={null}/>
                              <Link to={'/farm/add'} className={'text-decoration-none text-light'}>เพิ่มสวน</Link>
                          </button>
                          <button className={'btn btn-outline-danger'} onClick={()=>handleLogout()}>ออกจากระบบ</button>
                      </div>
                  </div>
              </ModalBody>
          </Modal>

        </>
    )
}

export default ModalCheckFarm;