import {create} from 'apisauce'
import {actionSetAppToken, actionSetUser} from "../redux/app";
import {store} from "../redux/store";

const ApiImage = create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    responseType: 'blob',
    headers: {
        Accept: 'application/json; charset=utf-8',
    }
})

export function setTokenImage(token) {
    ApiImage.setHeader('Authorization', `Bearer ${token}`)
}

ApiImage.axiosInstance.interceptors.response.use(response => response, (error) => {
    if (error.response && error.response.status === 401) {
        setTokenImage('')
        store.dispatch(actionSetAppToken(null))
        store.dispatch(actionSetUser(null))
        return error.response
    } else {
        return error.response
    }
})

export default ApiImage;
