import {Link, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import setInputNumberForDevice from "../helper/SetInputNumberForDevice";
import {EditApp, getAppDetail, getRef} from "../api/ApplicationAPI";
import ValidateMimeType from "../helper/ValidateMimeType";
import {
    alertError,
    alertSuccess,
    confirmPopupText,
    dismissPopup,
    showProgress
} from "../components/AlertDialogs";
import HeaderFarmer from "./layouts/HeaderFarmer";
import Layout from "./layouts/Layout";
import imageEmtry from "../assets/images/image_emtry.png";
import Footer from "./layouts/Footer";
import accept_icon from "../assets/icons/accept_icon.png";
import {store} from "../redux/store";
import Pageline from "../assets/icons/Pageline";
import Seedling from "../assets/icons/Seedling";
import Trillium from "../assets/icons/Trillium";
import Flower from "../assets/icons/Flower";
import Olive from "../assets/icons/Olive";
import Basket from "../assets/icons/Basket";

function UpdateApp() {
    const routeParams = useParams();
    const [app, setApp] = useState(null);
    const [file_preview, setFilePreview] = useState();
    const [confirmtoken, setConfirmToken] = useState(false);
    const navigate = useNavigate();
    const [type, setType] = useState({});


    const types = {
        1: ['ตัดแต่งกิ่ง', '#EB7100', '#FFEEDF', <Pageline width='20' height='20' color='#EB7100'/>],
        2: ['ใบแก่', '#53853D', '#EEFCE8', <Seedling width='20' height='20' color='#53853D'/>],
        3: ['ชักนำช่อดอก', '#C19700', '#FFF8D1', <Trillium width='20' height='20' color='#C19700'/>],
        4: ['แทงช่อดอก', '#CB262D', '#FFE7E7', <Flower width='20' height='20' color='#CB262D'/>],
        5: ['ติดผล', '#82881D', '#F1F3C8', <Olive width='20' height='20' color='#82881D'/>],
        6: ['เก็บเกี่ยวผลผลิต', '#0DA1AB', '#C3F4F8', <Basket width='20' height='20' color='#0DA1AB'/>],
    };


    const handleButtonClick = (typeKey) => {
        setType((prevType) => {
            const newType = {...prevType};
            if (typeKey in newType) {
                delete newType[typeKey];
            } else {
                newType[typeKey] = true;
            }
            return newType;
        });
    };

    useEffect(() => {
        getDetail()
        setInputNumberForDevice()
    }, []);

    const refreshToken = () => {
        getRef()
            .then(res => {
                setData('token', res.data);
            })
            .catch(error => {
                console.error('Error getting token:', error);
            });
    }

    const confirmRefreshToken = () => {
        confirmPopupText('', 'คุณต้องการแก้ไข token ใช่หรือไม่ ?', () => {
            setData('is_refresh_token', true)
            refreshToken();
        })
    }

    const getDetail = () => {
        showProgress('')
        if (store.getState().app.user.type === 'FARMER') {
            navigate('/application')
        } else {
            getAppDetail(routeParams.id)
                .then(res => {

                    if (res && res.data.success) {
                        let data = {
                            ...res.data.data,
                            token: res.data.data.personal_access_token.token
                        }

                        delete data.personal_access_token
                        setApp(data);
                        setType(data.show_display)
                        setFilePreview(res.data.data.full_picture_path)
                    } else {
                        //
                    }
                }).finally(() => {
                dismissPopup()
            })
        }
    }

    const handleChangeFile = (e) => {
        let is_valid = ValidateMimeType(e.target.files[0]);
        if (!is_valid) {
            alertError('', 'ไฟล์ต้องเป็นประเภท .png หรือ .jpg เท่านั้น')
            return
        }
        setData('picture', e.target.files[0])

        if (e.target.files && e.target.files[0]) {
            let reader = new FileReader();

            reader.onload = function (ev) {
                setFilePreview(ev.target.result)
            };

            reader.readAsDataURL(e.target.files[0]);
        }
    }


    const setData = (key, value) => {
        setApp(exitingData => ({
            ...exitingData,
            [key]: value
        }))
    }

    const removeImage = () => {
        setData('picture', null)
        setFilePreview(null)
    }

    const submit = () => {
        confirmPopupText(' ', 'คุณต้องการแก้ไขหรือไม่', () => {
            if (file_preview == null) {
                alertError('เกิดข้อผิดพลาด', 'กรุณาอัปโหลดไฟล์')
            } else {
                showProgress('')
                EditApp(app, type)
                    .then(res => {
                        if (res.data && res.data.success) {
                            alertSuccess('แก้ไขข้อมูลสำเร็จ')
                            setTimeout(() => {
                                dismissPopup()
                                navigate('/app-manage')
                            }, 1000)
                        } else {
                            alertError('แก้ไขข้อมูลไม่สำเร็จ', (res.data && res.data.message) || '')
                        }
                    })
            }


        })

    }


    return (
        <>
            <div className={'Container'}>
                <HeaderFarmer HeadTitle={'แก้ไขแอปพลิเคชัน'} showBack={true} showNoti={true}/>
                <Layout>
                    <div className="form-group mb-2">
                        <label className='text-color-gray form-label'>ชื่อ Application</label>
                        <input className='form-control'
                               type="text"
                               placeholder="ระบุชื่อ Application"
                               name={'name'}
                               onChange={(e) => setData(e.target.name, e.target.value, e)}
                               value={(app && app.name) || ''}
                        />
                    </div>

                    <div className={'form-group'}>
                        <label className='text-color-gray form-label'>Token</label>
                        <div className="form-group mb-2">
                            <input className='form-control w-100' type="text" placeholder="" readOnly={true}
                                   name="token"
                                   value={(app && app.token) || ''}/>
                        </div>
                    </div>

                    <div className={'row gx-2'}>
                        <div className={'col-6'}>
                            <button className={'btn btn-warning'} style={{height: 40}} onClick={confirmRefreshToken}
                                    disabled={confirmtoken}>
                                <i className="fa-solid fa-rotate-left"></i>
                                <span className={'ms-1'}>เปลี่ยน</span>
                            </button>
                        </div>
                        <div className={'col-6'}>
                            <button className={'btn btn-primary'} style={{height: 40}}
                                    onClick={() => setConfirmToken(true)} disabled={confirmtoken}>
                                <i className="fa-solid fa-check"></i>
                                <span className={'ms-1'}>ยืนยัน</span>
                            </button>
                        </div>
                    </div>

                    <div className={'form-group mb-2'}>
                        <label className={'text-color-gray form-label'}>URL</label>
                        <input className={'form-control'}
                               type="text"
                               placeholder={'ระบุ URL'}
                               name={'url'}
                               onChange={(e) => setData(e.target.name, e.target.value, e)}
                               value={(app && app.url) || ''}
                        />
                    </div>

                    <div className={'form-group'}>
                        <label className={'form-label text-color-gray'}>รูปหน้าปก</label>
                        <div className={'mb-2 overflow-hidden position-relative'}
                             style={{borderRadius: 12, border: '1px solid #B4D1C1', height: 200}}>
                            {file_preview ? (
                                <div className={'w-100 h-100'}>
                                    <button onClick={() => removeImage()} className={'position-absolute btn-clear'}
                                            style={{right: 10, top: 12}}>
                                        <i className="fa-solid fa-minus icon text-color-danger bg-dan"></i>
                                    </button>
                                    {/* eslint-disable-next-line jsx-a11y/alt-text,jsx-a11y/img-redundant-alt */}
                                    <img src={file_preview ? file_preview : ''} alt='no image'
                                         className={'w-100 h-100 object-fit-contain'}/>
                                </div>
                            ) : (
                                <>
                                    <div className={'w-100 d-flex justify-content-center align-items-center mt-3'}>
                                        <div
                                            className={'p-5 bg-second2 rounded-circle d-flex align-items-center justify-content-center'}
                                            style={{height: 50, width: 50}}>
                                            <i className="fa-solid fa-image text-color-primary"
                                               style={{fontSize: 30}}></i>
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="custom-file position-relative d-flex justify-content-center">
                                        <button className={'btn btn-warning text-light'}
                                                style={{height: 40, width: '50%'}}>
                                            <i className="fa-solid fa-image"></i>
                                            &nbsp;
                                            <input type={'file'} style={{opacity: 0, right: 0,width:'100%'}}
                                                   className={'position-absolute '}
                                                   id={'user-image'}
                                                   onChange={handleChangeFile}
                                            />
                                            <span style={{fontSize: 16}}>อัปโหลดรูปภาพ</span>
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className={'row'}>
                        <label className={'form-label text-color-gray'}>ตัวอย่างการแสดงผล</label>
                        <div className={'col-6'}>
                            <div className={'card card-in-home position-relative overflow-hidden'} style={{
                                width: '100%',
                                height: 200,
                                background: (app && app.background_style) || '',
                                padding: 0
                            }}>
                                <div style={{padding: '14px 15px'}}>
                                    <h6 style={{
                                        fontSize: 18,
                                        color: "white",
                                        marginBottom: 7,overflow:"hidden",
                                        textOverflow:"ellipsis",whiteSpace:"nowrap"
                                    }}>{((app && app.name) || '')}</h6>
                                    <Link to={((app && app.url) || '')} target={"_blank"}>
                                        <button className={'btn btn-in-home text-light'}
                                                style={{background: ((app && app.button_style) || '')}}>เลือก >
                                        </button>
                                    </Link>
                                </div>
                                {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                                <img src={file_preview ? file_preview : imageEmtry} alt="no image" className={''}
                                     style={{bottom: 0, right: 0, left: 0}} height={117} width={'100%'}/>
                            </div>
                        </div>
                        {/*input color customize card*/}
                        <div className="col-6">
                            <div className="row">
                                <div className={'col-12'}>
                                    <label className={'form-label'}>เลือกสีพื้นหลัง</label>
                                    <div className={'d-flex justify-content-start'}>
                                        <input type="color" className={'w-100 p-0 rounded-0'}
                                               name={'background_style'}
                                               onChange={(e) => setData(e.target.name, e.target.value, e)}
                                               value={(app && app.background_style) || ''}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className={'col-12'}>
                                    <label className={'form-label'}>เลือกสีปุ่ม</label>
                                    <div className={'d-flex justify-content-start'}>
                                        <input type="color" className={'w-100 p-0 rounded-0'}
                                               name={'button_style'}
                                               onChange={(e) => setData(e.target.name, e.target.value, e)}
                                               value={(app && app.button_style) || ''}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <label className={'form-label text-color-gray mt-2'}>ระยะพัฒนาการ</label>
                        <div className='row g-2'>
                            {Object.keys(types).map((typeKey, index) => (
                                <div key={index} className='col-6'>
                                    <div
                                        className='d-flex p-2'
                                        style={{background: type[typeKey] ? types[typeKey][2] : '', borderRadius: 14}}
                                    >
                                        <div className='mx-2'>
                                            <input
                                                onClick={() => handleButtonClick(typeKey)}
                                                id={`checkStatus${index}`}
                                                className='form-check-input'
                                                type='checkbox'
                                                checked={type[typeKey]}
                                            />
                                        </div>
                                        <div className='me-2'>{types[typeKey][3]}</div>
                                        <span style={{color: types[typeKey][1], fontSize: 12}}>{types[typeKey][0]}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <button className={'btn btn-primary mt-3'} onClick={submit}>
                        <img src={accept_icon} alt=""/>&nbsp;
                        <span>ตกลง</span>
                    </button>
                </Layout>
                <Footer/>
            </div>
        </>
    )
}

export default UpdateApp
