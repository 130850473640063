import ReactSwitch from "react-switch";
import {useEffect, useState} from "react";
import {CircularProgressbar, buildStyles} from "react-circular-progressbar";
import 'react-circular-progressbar/dist/styles.css'
import _ from "lodash";
import {useSelector} from "react-redux";
import {controlSensorById, getSensorsByStation, getThingIOTByStation} from "../../api/WeatherApi";
import {faAngleLeft, faList} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useNavigate} from "react-router-dom";

const Sensor = ({stationId}) => {
    const [sensors, setSensors] = useState([])
    const [things, setThings] = useState([])
    const navigate = useNavigate();
    /*[
      {name: 'Water Level', is_on: true, value: 3.41, unit: 'cm', updated_at: 'a month ago'},
      {name: 'Vout Sensor', is_on: false, value: 3.62, unit: 'v', updated_at: 'a month ago'},
      {name: '5V Supply', is_on: false, value: 5.11, unit: 'v', updated_at: 'a month ago'},
      {name: 'NTC Temp', is_on: false, value: 28.21, unit: 'c', updated_at: 'a month ago'},
      ]*/

    const user = useSelector(state => {
        return state && state.app && state.app.user
    })

    const handleChangeSensor = (checked, index) => {
        let data = _.clone(things);
        data[index].is_on = checked
        data[index].sensor_value = checked ? 1 : 0
        controlSensorById(stationId, data[index])
            .then(res => {
                if (res.data && res.data.success) {
                    setThings(res.data.data)
                }
            })
    }

    useEffect(() => {
        if (!stationId) {
            return
        }
        getSensors()
        getThingIOT()
    }, [stationId]);

    const getSensors = () => {
        getSensorsByStation(stationId)
            .then(res => {
                if (res.data && res.data.success) {
                    setSensors(res.data.data)
                }
            })
    }

    const getThingIOT = () => {
        getThingIOTByStation(stationId)
            .then(res => {
                if (res.data && res.data.success) {
                    setThings(res.data.data)
                }
            })
    }

    return (
        <div>
            <div className="sensor-container mt-4">
                <div className={'d-flex justify-content-between'}>
                    <div className="title justify-content-center align-content-center align-middle">Sensor</div>
                    <div>
                        {
                            stationId && (
                                <button className="btn btn-primary btn-sm" onClick={() => navigate(`/sensors/${stationId}`)}>
                                    Data History &nbsp;
                                    <FontAwesomeIcon  icon={faList}/>
                                </button>
                            )
                        }

                    </div>
                </div>


                <div className="d-flex flex-wrap justify-content-start">
                    {
                        sensors && sensors.length > 0 ?
                            sensors.map((item, index) => (
                                // typeof item.value === 'number' &&
                                <div className="text-center sensor-box p-2 w-47" key={`sensor_${index}`}>
                                    <div>
                                        <CircularProgressbar value={parseFloat(item.value).toFixed(2)}
                                                             text={`${parseFloat(item.value).toFixed(2)} ${item.unit || ''}`}
                                                             styles={buildStyles({
                                                                 rotation: 0.5,
                                                                 // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                                 strokeLinecap: 'round',

                                                                 // Text size
                                                                 textSize: '18px',

                                                                 // How long animation takes to go from one percentage to another, in seconds
                                                                 pathTransitionDuration: 0.5,

                                                                 // Colors
                                                                 // pathColor: item.is_enable ? '#0E572B' : '#D8D8D8',
                                                                 pathColor: '#0E572B',
                                                                 textColor: '#0E572B',
                                                                 trailColor: '#EBF3ED'
                                                                 // trailColor: item.is_enable ? '#EBF3ED' : '#F5F5F5'
                                                             })}
                                        />
                                    </div>

                                    {/*<div style={{color: '#727178', fontSize: 12, fontWeight: 700}}>{item.updated_at}</div>*/}
                                    <div style={{fontSize: 20, color: '#222222', fontWeight: 600}}
                                         className="mt-1">{item.sensor_name}</div>
                                </div>
                            ))
                            :
                            <div className="mt-3">
                                ไม่พบข้อมูลเซนเซอร์
                            </div>
                    }
                </div>
            </div>

            <div className="things-container mt-4">
                <div className="title">แผงควบคุม</div>

                <div>

                    {
                        things && things.length > 0 ?
                            things.map((item, index) => (
                                <div className="card-thing-iot mt-2">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div>{item.iot_name}</div>
                                        <ReactSwitch checked={item.value}
                                                     onChange={(c) => handleChangeSensor(c, index)}
                                                     checkedIcon={false}
                                                     uncheckedIcon={false}
                                                     onColor={'#0E572B'}
                                                     offColor={'#A7A7A7'}
                                                     id={`sensor_${index}`}
                                                     className="react-switch"
                                            // disabled={true}
                                        />
                                    </div>
                                </div>
                            ))
                            :
                            <div className="mt-2">
                                ไม่พบข้อมูลอุปกรณ์ของผู้ใช้งาน
                            </div>
                    }
                </div>
            </div>
        </div>

    )
}

export default Sensor;
