import '../../assets/css/sensor_detail.css'
import {useEffect, useState} from "react";
import {
  exportControllerLogApi,
  exportSensorLogApi,
  getSensorLogForChart,
} from "../../api/WeatherApi";
import Layout from "../layouts/Layout";
import FooterFarmer from "../layouts/FooterFarmer";
import {alertWarning, dismissPopup, showProgress} from "../../components/AlertDialogs";
import {useSelector} from "react-redux";
import Footer from "../layouts/Footer";
import LineChart from "../../components/LineChart";
import {Link, useNavigate, useParams} from "react-router-dom";
import {faAngleLeft, faDownload} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody} from "reactstrap";

import DatePicker, {registerLocale, setDefaultLocale} from "react-datepicker";
import {th} from 'date-fns/locale/th';
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

registerLocale('th', th)

const SensorDetail = () => {
  const routeParams = useParams();
  const navigate = useNavigate();
  const [datasets, setDatasets] = useState(null)
  const [period, setPeriod] = useState('1h')
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const user = useSelector(state => {
    return state && state.app && state.app.user
  })
  // const datasets = [
  //     {
  //         label: 'EDS',
  //         backgroundColor: 'rgb(255, 99, 132)',
  //         data: [
  //             {x: "2018-01-01 14:00:00", y: 11},
  //             {x: "2018-02-03 15:00:00", y: 5},
  //             {x: "2018-03-01 16:00:00", y: 14},
  //             {x: "2018-02-01 17:00:00", y: 3},
  //             {x: "2018-06-01 18:00:00", y: 2}
  //         ]
  //     }
  // ]
  useEffect(() => {
    if (period !== 'custom') {
      getData()
    }
  }, [period]);
  const getData = () => {
    if (period === 'custom') {
      if (startDate === '' && endDate === '') {
        return
      }
    }
    getSensorLogForChart(routeParams.id, {
      period: period,
      start_date: startDate ? moment(startDate).format("YYYY-MM-DD HH:mm:ss") : '',
      end_date: endDate ? moment(endDate).format("YYYY-MM-DD HH:mm:ss") : ''
    })
      .then(res => {
        if (res?.data?.success) {
          setDatasets(res.data.data)
        }
      }).finally(() => {
      dismissPopup()
    })
  }

  const exportControllerLogsData = async () => {
    await exportControllerLogApi(routeParams.id, {period: period}).then((response) => {
      if (response.ok && response.data) {
        let date = new Date();
        const url = window.URL.createObjectURL(response.data)
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `สรุป ControllerLogs_${date.getTime()}.xlsx`);
        document.body.appendChild(link)
        link.click()
        window.URL.revokeObjectURL(url)
        link.remove()
      }
    })
  }

  const exportSensorLogsData = async () => {
    await exportSensorLogApi(routeParams.id, {period: period}).then((response) => {
      if (response.ok && response.data) {
        let date = new Date();
        const url = window.URL.createObjectURL(response.data)
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `สรุป SensorLogs_${date.getTime()}.xlsx`);
        document.body.appendChild(link)
        link.click()
        window.URL.revokeObjectURL(url)
        link.remove()
      }
    })
  }
  const fillColor = (data) => {
    return data.includes(period) ? '#20c997' : '#6c757d';
  }

  const [modal, setModal] = useState(false)
  const handleSubmit = () => {
    if (startDate !== '' || endDate !== '') {
      setModal(false)
      getData()
    } else {
      alertWarning('', 'กรุณาเลือกช่วงเวลา')
    }
  }
  const handleOpen = () => {
    setPeriod('custom')
    setModal(true)
  }
  const handleClose = () => {
    setModal(false)
  }
  useEffect(() => {
    getData()
  }, [routeParams.id]);


  return (
    <div className="Container">
      <div className={"d-flex justify-content-between"} style={{padding: '1rem'}}>
        <div className={'justify-content-center align-content-center align-middle'}>
          <FontAwesomeIcon
            onClick={() => navigate('/sensors')}
            icon={faAngleLeft} style={{color: '#848484', fontSize: 20}}/>
        </div>
        <div className={'justify-content-center align-content-center align-middle'} style={{color: '#0E572B', fontSize: 20}}>
          Data History
        </div>
        <div className={'justify-content-center align-content-center align-middle'}>
          <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={'start'}>
            <DropdownToggle>
              <FontAwesomeIcon icon={faDownload} style={{color: '#848484', fontSize: 20}}/>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={exportSensorLogsData}>Sensor Logs</DropdownItem>
              <DropdownItem onClick={exportControllerLogsData}>Controller Logs</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
      <div id={'filters_sensor'} className={'me-3'}>
        <div className={'float-end'}>
          <div className="btn-group" role="group">
            <button type="button" className={"btn btn-sm btn-filter-sensor"}
                    onClick={() => setPeriod('1h')}
                    style={{backgroundColor: fillColor('1h')}}>1h
            </button>
            <button type="button" className="btn btn-sm btn-filter-sensor"
                    onClick={() => setPeriod('3h')}
                    style={{backgroundColor: fillColor('3h')}}>3h
            </button>
            <button type="button" className="btn btn-sm btn-filter-sensor"
                    onClick={() => setPeriod('12h')}
                    style={{backgroundColor: fillColor('12h')}}>12h
            </button>
            <button type="button" className="btn btn-sm btn-filter-sensor"
                    onClick={() => setPeriod('1d')}
                    style={{backgroundColor: fillColor('1d')}}>1d
            </button>
            <button type="button" className="btn btn-sm btn-filter-sensor"
                    onClick={() => setPeriod('3d')}
                    style={{backgroundColor: fillColor('3d')}}>3d
            </button>
            <button type="button" className="btn btn-sm btn-filter-sensor"
                    onClick={() => setPeriod('1w')}
                    style={{backgroundColor: fillColor('1w')}}>1w
            </button>
            <button type="button" className="btn btn-sm btn-filter-sensor"
                    onClick={() => handleOpen()}
                    style={{backgroundColor: fillColor('custom')}}>custom
            </button>
          </div>
        </div>
      </div>
      <Layout>
        {
          datasets && datasets.map((dataset, key) => {
            return (<LineChart key={key} datasets={dataset}></LineChart>)
          })
        }
      </Layout>
      {
        user.type === 'ADMIN' ? <Footer/> : <FooterFarmer/>
      }

      <Modal
        isOpen={modal}
        onClose={handleClose}
        centered={true}
        fade={true}
      >
        <ModalBody className={'position-relative'} style={{height: '50%'}}>
          <div className={'text-left'}>
            <div className={'mt-3'}>
              <div className='mb-3'>
                <label>
                  วันเวลาที่เริ่ม
                </label>
                <DatePicker
                  className={'form-control'}
                  locale="th"
                  placeholderText={'เลือกวันเวลาที่เริ่ม'}
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  showTimeSelect
                  timeFormat="p"
                  timeIntervals={10}
                  dateFormat="Pp"
                />
              </div>
              <div>
                <label>
                  วันเวลาที่สิ้นสุด
                </label>
                <DatePicker
                  className={'form-control'}
                  locale="th"
                  placeholderText={'เลือกวันเวลาที่เริ่ม'}
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  showTimeSelect
                  timeFormat="p"
                  timeIntervals={10}
                  dateFormat="Pp"
                />
              </div>
            </div>
            <div className="mt-3">
              <button type="button"
                      className="btn btn-confirm-approve text-white w-100 d-flex justify-content-center align-items-center mb-2"
                      style={{background: '#0E572B', borderRadius: 50, padding: 13}}
                      onClick={() => handleSubmit()}
              >
                ตกลง
              </button>
              <button className={'btn btn-outline-danger'} onClick={() => handleClose()}>ปิด</button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default SensorDetail;
