import sunIcon from '../../assets/icons/sun.png'
import moistureIcon from '../../assets/icons/moisture.png'
import rainfallIcon from '../../assets/icons/rainfall.png'
import windDirectionIcon from '../../assets/icons/wind_direction.png'
import windSpeedIcon from '../../assets/icons/wind_speed.png'

const WeatherStation = ({data, stationData}) => {
  return (
    <div className="weather-container">
      <div className="d-flex justify-content-between align-items-start">
        <div className="d-flex align-items-center">
          <img src={sunIcon}/>

          <div className="ms-2">
            <div>ความเข้มแสง</div>
            <div><b>{data?.light_intensity || 0}</b> lux</div>
          </div>
        </div>

        <div className="text-end">
          <div style={{fontSize: 15}}>แปลงวิจัยลำไย :  {stationData?.name_th || '-'}</div>
          <div style={{fontSize: 48, marginTop: -13}}>{data?.temperature || 0} <sup style={{fontSize: 20}}>°C</sup></div>
        </div>
      </div>

      <div className="d-flex flex-1 justify-content-around mt-3">
        <div className="flex-1 text-center">
          <div>ความชื้น</div>
          <img src={moistureIcon}/>
          <div>{data?.moisture || 0} %</div>
        </div>

        <div className="flex-1 text-center">
          <div>ปริมาณน้ำฝน</div>
          <img src={rainfallIcon}/>
          <div>{data?.rainfall || 0} mm/hr</div>
        </div>

        <div className="flex-1 text-center">
          <div>ทิศทางลม</div>
          <img src={windDirectionIcon}/>
          <div>{data?.wind_direction || 0}</div>
        </div>

        <div className="flex-1 text-center">
          <div>ความเร็วลม</div>
          <img src={windSpeedIcon}/>
          <div>{data?.wind_speed || 0} m/s</div>
        </div>
      </div>
    </div>
  )
}

export default WeatherStation
